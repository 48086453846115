
import { Component, Vue, Prop, Getter } from "nuxt-property-decorator";
import { Genre } from "@/types/Genre";
import { HeaderData } from "~/types/components";
import {
  BIcon,
  BIconHouse,
  BIconWatch,
  BIconWallet2,
  BIconBuilding,
  BIconThreeDots,
  BIconFilter,
  BIconArrowRightCircle,
  BIconPen,
  BIconArrowLeftCircle,
  BIconArrowLeftShort,
  BIconBook,
  BIconList,
} from "bootstrap-vue";

@Component({
  components: {
    BIcon,
    BIconHouse,
    BIconWatch,
    BIconWallet2,
    BIconBuilding,
    BIconThreeDots,
    BIconFilter,
    BIconArrowRightCircle,
    BIconPen,
    BIconArrowLeftCircle,
    BIconArrowLeftShort,
    BIconBook,
    BIconList,
  },
})
export default class SectionSearch extends Vue {
  @Prop() isScrolled!: boolean;
  @Prop() filtersCount: Number;

  @Getter("layout/header") headerHeight: number;
  @Getter("layout/offset") offset: number;
  @Getter("tenant/headerData") headerData!: HeaderData;
  @Getter("tenant/isAsta") isAsta!: boolean;
  @Getter("preset/allGenres") allGenres!: Genre[];
  @Getter("filter/genre") currentGenere!: string;

  public genres: Array<Genre> = [];

  mounted() {
    this.genres = this.allGenres.filter((g: Genre) => {
      return g.disabled === false;
    });
  }

  getGenreLabel(alias: string) {
    alias = alias.replace("/", "");
    return this.genres.find((g) => g.alias === alias)?.text || "Cerca";
  }

  changeGenre(event: any) {
    this.$scrollOntop();
    this.$store.commit("typesense/clearResults");
    this.$store.commit("filter/resetFilterMutation", this.$store.getters["tenant/ivgId"]);
    this.$store.commit("filter/commitIvgPayload", this.$store.getters["tenant/ivgPayload"]);
    this.$store.commit("filter/setGenreFilter", [event.value]);
    this.$store.commit("paginator/resetToPage");
    this.$router.push({ name: event.alias.toLowerCase() });
    if (this.$router.currentRoute.name === event.value.toLowerCase().replace("/", "")) {
      this.$store.dispatch("typesense/getData");
    }
  }

  openFilters() {
    this.$store.commit("modal/toggleModal");
  }
}


import { Component, Vue } from "nuxt-property-decorator";

@Component
export default class TheA extends Vue {
  //public delay: boolean = false;
  // mounted() {
  //   setTimeout(() => {
  //     this.delay = true;
  //   }, 250);
  // }

  public getLink() {
    if (this.$store.getters["preset/style"] === "crediti") {
      return "valoricrediti";
    }

    return this.$store.getters["preset/style"];
  }
}
